import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
  console.log("updated");
}

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

